import { observer } from 'mobx-react-lite'
import { ComponentProps } from '@stitches/react'
import { Text } from 'primitives/Text'
import { Box } from 'primitives/Box'
import { theme } from 'lib/theme'
import { ProfileListItem } from 'common/ProfileListItem'
import { useStore } from 'lib/store'
import { Flex } from 'primitives/Flex'

export interface SuggestionsMenuProps extends ComponentProps<typeof Box> {}

const _SuggestionsMenu: React.FC<SuggestionsMenuProps> = ({ css }) => {
  const store = useStore()
  const suggestionsStore = store.user.suggestions
  return (
    <Box
      css={{
        backgroundColor: theme.colors.gray300,
        borderRadius: '6px',
        overflowX: 'hidden',
        ...css,
      }}
    >
      <Text
        type='body4'
        medium
        css={{
          margin: '16px 0px 10px 16px',
          color: theme.colors.gray800,
        }}
        as='h3'
      >
        Suggestions
      </Text>
      {suggestionsStore.profiles.length > 0 ? (
        suggestionsStore.profiles.map((profile) => (
          <ProfileListItem key={profile.profileId} profile={profile} />
        ))
      ) : (
        <Flex
          center
          css={{
            alignItems: 'center',
            padding: '16px',
            backgroundColor: theme.colors.gray300,
          }}
        >
          <Text type='body3' subText>
            {suggestionsStore.loading
              ? 'Loading'
              : 'No more suggestions at this time'}
          </Text>
        </Flex>
      )}
    </Box>
  )
}
export const SuggestionsMenu = observer(_SuggestionsMenu)
