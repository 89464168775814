import { ComponentProps } from '@stitches/react'
import { Link } from 'common/Link'
import { AppRoutes } from 'config/routes'
import { Account, FeedType, SortBy } from 'graphql/types'
import { theme } from 'lib/theme'
import { useRouter } from 'next/router'
import { Heading } from 'primitives/Heading'
import { List } from 'primitives/List'
import { ListItem } from 'primitives/ListItem'
import { Nav } from 'primitives/Nav'
// import { SelectPopover } from 'common/Inputs/SelectPopover'
// import { useForm } from 'react-hook-form'

export interface CategoriesMenuProps extends ComponentProps<typeof Nav> {
  user?: Account
}

export const FeedsMenu: React.FC<CategoriesMenuProps> = ({ css, user }) => {
  const { pathname } = useRouter()

  // SelectPopover
  // const { control, handleSubmit, watch } = useForm()

  const FEED_ITEMS = [
    {
      name: 'Discover',
      description: 'Find creators and posts you may have missed. Changed every few hours.',
      path: AppRoutes.DISCOVER,
      isVisible: true
    },
    {
      name: 'Latest',
      description: 'Browse the newest posts from all creators.',
      path: AppRoutes.LATEST_FEED,
      isVisible: !!user
    },
    {
      name: 'Following',
      description: 'See posts from creators you follow.',
      path: AppRoutes.FOLLOWING_FEED,
      isVisible: !!user
    },
    {
      name: 'Liked posts',
      description: 'See posts you have liked.',
      path: AppRoutes.LIKES_FEED,
      isVisible: !!user
    }
  ]

  return (
    <Nav
      css={{
        backgroundColor: theme.colors.gray300,
        overflowX: 'auto',
        padding: '0px 16px',
        '&::-webkit-scrollbar': {
          height: 0
        },
        '@lg': {
          padding: '0px',
          backgroundColor: 'transparent'
        },
        ...css
      }}
    >
      <Heading
        as="h6"
        css={{
          lineHeight: '16px',
          marginBottom: '18px',
          display: 'none',
          '@lg': {
            display: 'block'
          }
        }}
      >
        Feeds
      </Heading>

      {/* TODO: Add styling */}
      {/* TODO: Add styling */}
      {/* TODO: Add styling */}
      {/* <SelectPopover control={control} name="selectedFeed" options={FEED_ITEMS} /> */}

      <List
        css={{
          display: 'flex',
          listStyleType: 'none',
          '@sm': {
            justifyContent: 'center'
          },
          '& li button': {
            whiteSpace: 'nowrap'
          },
          '& li:first-child': {
            marginRight: '4px'
          },
          '& li:not(:first-child)': {
            // margin: '0 4px',
          },
          '@lg': {
            display: 'block',
            '& li:not(:last-child)': {
              marginBottom: '12px'
            }
          }
        }}
      >
        {FEED_ITEMS.filter((x) => x.isVisible).map(({ name, path }, i) => {
          console.log('category', pathname)
          return (
            <ListItem key={name}>
              <Link
                pill
                active={pathname === path.toLowerCase()}
                href={path.toLowerCase()}
                css={{ margin: 0, padding: '4px 10px', textAlign: 'left' }}
              >
                {name}
              </Link>
            </ListItem>
          )
        })}
      </List>
    </Nav>
  )
}
