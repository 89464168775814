import { MouseEventHandler } from 'react'
import { ComponentProps } from '@stitches/react'
import { Text } from 'primitives/Text'
import { Box } from 'primitives/Box'
import { Flex } from 'primitives/Flex'
import { theme } from 'lib/theme'
import { Button } from 'common/Button'
import { Profile } from 'models/Profile'
import { useToggleFollowProfile } from 'hooks/useToggleFollowProfile'
import { observer } from 'mobx-react-lite'
import { Avatar } from 'common/Avatar'
import { AppRoutes } from 'config/routes'
import { Link } from 'common/Link'
import { useStore } from 'lib/store/useStore'
import { NSFW_EVENT } from 'lib/tracking/types'
import { SnackBar } from 'common/SnackBar'
import { useRouter } from 'next/router'
import { toast } from 'components/common/Toast'
import snackBar from 'common/SnackBar/config'

export interface ProfileListItemProps extends ComponentProps<typeof Box> {
  profile: Profile
}

const _ProfileListItem: React.FC<ProfileListItemProps> = ({ profile, css }) => {
  const profilePath = `${AppRoutes.PROFILE}/${profile?.username}`
  const store = useStore()
  const { toggleFollowProfile } = useToggleFollowProfile(profile)
  const { push } = useRouter()

  const handleFollowProfile: MouseEventHandler<HTMLButtonElement> = (e) => {
    toggleFollowProfile(e).then(({ success, following }) => {
      if (success && following) {
        store.analytics.track(NSFW_EVENT.SUGGESTIONS_PROFILE_FOLLOW, {
          profileId: profile.profileId,
          username: profile.username
        })

        toast({
          message: `You are now following: ${profile.username}`,
          type: 'success',
          duration: 1500,
          action: {
            label: 'View',
            onClick: () => push(`${AppRoutes.PROFILE}/${profile.username}`)
          }
        })

        store.user.suggestions.fetch()
      }
    })
  }

  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '-2px',
        textDecoration: 'none',
        padding: '16px',
        borderRadius: '6px',
        transition: 'background-color 200ms ease',
        backgroundColor: theme.colors.gray300,
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: theme.colors.gray400
          }
        },
        ...css
      }}
    >
      <Link
        href={profilePath}
        nounderline
        onClick={() => {
          store.analytics.track(NSFW_EVENT.SUGGESTIONS_PROFILE_CLICK, {
            profileId: profile.profileId,
            username: profile.username
          })
        }}
      >
        <Flex
          css={{
            justifyContent: 'space-between'
          }}
        >
          <Avatar username={profile?.username} src={profile?.avatar?.url} size="small" />
          <Box css={{ marginLeft: '8px' }}>
            <Text type="body3">{profile.displayName}</Text>
            <Text type="body4" css={{ marginTop: '4px' }}>
              @{profile.username}
            </Text>
          </Box>
        </Flex>
      </Link>
      <Button
        follow
        pill
        css={{
          width: 'auto',
          border: `1px solid ${theme.colors.gray400}`,
          backgroundColor: theme.colors.gray500,
          fontSize: '12px',
          lineHeight: '16px'
        }}
        onClick={handleFollowProfile}
      >
        {profile.followingActionLabel}
      </Button>
    </Box>
  )
}

export const ProfileListItem = observer(_ProfileListItem)
