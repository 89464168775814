import { ComponentProps, styled } from 'lib/theme'
import { Flex } from 'primitives/Flex'

export const ModalContentContainer = styled(Flex, {
  backgroundColor: '$gray100',
  position: 'fixed',
  flexDirection: 'column',
  padding: '16px',
  width: '100%',
  height: '100%',
  '@lg': {
    padding: '32px',
  },
})

// This is the full screen variation of ModalContentDialog.
export const ModalContent = ({
  children,
  css,
  ...args
}: ComponentProps<typeof Flex>) => (
  <ModalContentContainer
    css={{
      ...css,
      '@lg': {
        ...css?.['@lg'],
      },
    }}
    {...args}
  >
    {children}
  </ModalContentContainer>
)
