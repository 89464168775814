import { ComponentProps } from 'lib/theme'
import { Modal } from 'common/Modal'
import { ModalCloseButton } from 'common/Modal/ModalCloseButton'
import { ModalContent } from 'common/Modal/ModalContent'
import { Video } from 'primitives/Video'
import { FeedPostVideoAsset } from 'models/FeedPostVideoAsset'
import { useStore } from 'lib/store'
import { ControlType, NSFW_EVENT } from 'lib/tracking/types'
import { useMemo } from 'react'

interface PostVideoModalProps extends ComponentProps<typeof Modal> {
  asset?: FeedPostVideoAsset
  onClose: () => void
  isMobile?: boolean
}

export const PostVideoModal = ({
  asset,
  onClose,
  css,
  isMobile,
}: PostVideoModalProps) => {
  const store = useStore()

  const srcSets = useMemo(
    () => [
      {
        src: asset?.media.url || '',
        type: 'video/webm',
      },
      {
        src: asset?.media.fallbackUrl || '',
        type: 'video/mp4',
      },
    ],
    [asset?.media]
  )

  return (
    <Modal
      isOpen={!!asset}
      css={{
        ...css,
      }}
      onBackgroundClick={() => onClose()}
    >
      <ModalCloseButton
        css={
          isMobile
            ? // mobile html5 full screen has some controls at the top, until we build
              // a custom fullscreen player, just move the modal close button to custom position.
              {
                top: '46px',
                right: '6px',
                borderRadius: '8px',
                minWidth: '54px',
                maxHeight: '20px !important',
                height: '20px !important',
                opacity: 0.8,
                svg: {
                  width: '32px',
                  height: '32px',
                },
              }
            : {}
        }
        onClick={() => onClose()}
      />
      <ModalContent
        css={{ padding: 0, overflow: 'hidden', '@lg': { padding: 0 } }}
      >
        <Video
          controls
          playsinline
          playing
          url={srcSets}
          initDuration={asset?.lastDuration}
          onEnded={() => asset?.setIsWatched(true)}
          onSeek={() =>
            store.analytics.track(NSFW_EVENT.VIDEO_CONTROLS, {
              feedType: store.feed.currentFeedType,
              postId: asset?.parentPost.postId,
              controlType: ControlType.SCRUBBER,
            })
          }
        />
      </ModalContent>
    </Modal>
  )
}
