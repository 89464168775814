import type { NextPage, NextPageContext } from 'next'
import type { Account } from 'graphql/types'
import type { NextPageMetaProps } from 'interfaces'
import merge from 'lodash/merge'
import { TOKEN_COOKIE } from 'config'
import { detectDevice } from 'lib/nextjs/nextDeviceDetect'
import { expireCookie } from 'lib/nextjs/expireCookie'
import { getCookie, pageCanonical } from 'lib/nextjs'

interface Props {
  meta: NextPageMetaProps
  store: {
    ui: { isMobile: boolean; referralCode?: string }
    user?: { account?: Account; token?: string }
  }
}

export interface WithPublicPageContext extends NextPageContext {
  isMobile: boolean
  authToken?: string
}

/* Wrap a Next page with authed requirement by fetching the user data.
 */
export const withPublicPage = (Page: NextPage<any>) => {
  // Allow Page to attach getInitialProps for page specific data fetching
  const _getInitialProps = Page.getInitialProps?.bind({})

  Page.getInitialProps = async (ctx: NextPageContext) => {
    const { isMobile } = detectDevice(ctx)
    const authToken = getCookie(TOKEN_COOKIE, ctx.req)

    // Param to expire the current session
    if (ctx.query.expire) {
      expireCookie(ctx, TOKEN_COOKIE)
    }

    const referralCode = Array.isArray(ctx.query.nsfwReferrer)
      ? ctx.query.nsfwReferrer[0]
      : ctx.query.nsfwReferrer

    const props: Props = {
      meta: {
        canonical: pageCanonical(ctx.asPath, ctx),
      },
      store: {
        ui: {
          isMobile,
          referralCode,
        },
      },
    }

    // Merge page initial props
    if (_getInitialProps) {
      merge(
        props,
        await _getInitialProps({
          ...ctx,
          ...{ isMobile, authToken },
        } as WithPublicPageContext)
      )
    }
    return props
  }

  return Page
}
