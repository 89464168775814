import { Icon, KnownIcon, Lock } from 'common/Icon'
import { Link } from 'common/Link'
import { AppRoutes, FEED_MAP } from 'config/routes'
import { Account, AccountType } from 'graphql/types'
import { useStore } from 'lib/store'
import { ComponentProps, keyframes, styled } from 'lib/theme'
import { useRouter } from 'next/router'
import { Flex } from 'primitives/Flex'
import { List } from 'primitives/List'
import { ListItem } from 'primitives/ListItem'
import { Nav } from 'primitives/Nav'
import { Text } from 'primitives/Text'
import { theme } from 'lib/theme'
import { Heading } from 'primitives/Heading'
import { Box } from 'primitives/Box'
import { GradientNavLink } from 'shared/GradientNSFWLink'
import { observer } from 'mobx-react-lite'

type LeftSideNavProps = ComponentProps<typeof Nav>

const LinkIconCss = {
  paddingRight: '10px'
}

const onlineFeedLinkCss = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  // Icon viewbox compensation
  // todo fix svgs
  marginLeft: '-10px'
}

const FeedLinkCss = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start'
}

const MenuSection = styled(Box, {
  marginBottom: 26
})

const MenuGroupList = styled(List, {
  display: 'flex',
  listStyleType: 'none',
  padding: '0 12px',
  '@sm': {
    justifyContent: 'center'
  },
  '& li button': {
    whiteSpace: 'nowrap'
  },
  '& li:first-child': {
    marginRight: '4px'
  },
  '& li:not(:first-child)': {
    // margin: '0 4px',
  },
  '@lg': {
    display: 'block',
    '& li:not(:last-child)': {
      marginBottom: '12px'
    }
  }
})

export const LeftSideNav: React.FC<LeftSideNavProps> = observer(({ css }) => {
  const store = useStore()
  const { pathname, asPath } = useRouter()

  return (
    <Nav
      css={{
        backgroundColor: theme.colors.gray300,
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
        padding: '0px 16px',
        '&::-webkit-scrollbar': {
          height: 0
        },
        '@lg': {
          padding: '0px',
          backgroundColor: 'transparent'
        },
        ...css
      }}
    >
      {store.user.isCreator && (
        <MenuSection>
          <Heading
            as="h6"
            css={{
              lineHeight: '16px',
              marginBottom: 9,
              marginTop: 0,
              color: '$gray800',
              display: 'none',
              '@lg': {
                display: 'block'
              }
            }}
          >
            Creator Tools
          </Heading>
          <MenuGroupList>
            <ListItem>
              <Link
                icon
                css={FeedLinkCss}
                href={AppRoutes.EARNINGS}
                active={asPath === AppRoutes.EARNINGS}
              >
                <Icon icon="Wallet" css={{ ...LinkIconCss, padding: 0, marginRight: 12 }} />
                <Text type="body2" capitalize>
                  Earnings
                </Text>
              </Link>
            </ListItem>
            <ListItem>
              <Link
                icon
                css={onlineFeedLinkCss}
                href={AppRoutes.SALES}
                active={asPath === AppRoutes.SALES}
              >
                <Icon icon="Sales" css={{ ...LinkIconCss, padding: 0 }} />
                <Text type="body2" capitalize>
                  Sales
                </Text>
              </Link>
            </ListItem>
            <ListItem>
              <Link
                icon
                css={onlineFeedLinkCss}
                href={AppRoutes.ANALYTICS}
                active={asPath === AppRoutes.ANALYTICS}
              >
                <Icon icon="Reports" css={{ ...LinkIconCss, padding: 0, marginRight: 4 }} />
                <Text type="body2" capitalize>
                  Stats
                </Text>
              </Link>
            </ListItem>
            <ListItem>
              <Link
                icon
                css={FeedLinkCss}
                href={AppRoutes.SCHEDULED_POSTS}
                active={asPath === AppRoutes.SCHEDULED_POSTS}
              >
                <Icon icon="Scheduled" css={{ ...LinkIconCss, padding: 0 }} />
                <Text type="body2" capitalize>
                  Scheduled
                </Text>
              </Link>
            </ListItem>
          </MenuGroupList>
        </MenuSection>
      )}

      <MenuSection>
        <Heading
          as="h6"
          css={{
            lineHeight: '16px',
            marginBottom: 9,
            color: '$gray800',
            display: 'none',
            '@lg': {
              display: 'block'
            }
          }}
        >
          Feeds
        </Heading>

        <MenuGroupList>
          <ListItem>
            <Link
              icon
              css={onlineFeedLinkCss}
              active={pathname === AppRoutes.DISCOVER.toLowerCase()}
              href={AppRoutes.DISCOVER.toLowerCase()}
            >
              <Icon icon="Search" css={{ ...LinkIconCss, padding: 11 }} />
              <Text type="body2" capitalize>
                Discover
              </Text>
            </Link>
          </ListItem>

          {store.user.isLoggedIn && (
            <>
              <ListItem>
                <Link
                  icon
                  css={onlineFeedLinkCss}
                  active={pathname === AppRoutes.LATEST_FEED.toLowerCase()}
                  href={AppRoutes.LATEST_FEED.toLowerCase()}
                >
                  <Icon icon="Asterisk" css={{ ...LinkIconCss, padding: 8 }} />
                  <Text type="body2" capitalize>
                    Latest
                  </Text>
                </Link>
              </ListItem>

              <ListItem>
                <Link
                  icon
                  css={onlineFeedLinkCss}
                  active={pathname === AppRoutes.FOLLOWING_FEED.toLowerCase()}
                  href={AppRoutes.FOLLOWING_FEED.toLowerCase()}
                >
                  <Icon icon="EyeOpen" css={{ ...LinkIconCss, padding: 11 }} />
                  <Text type="body2" capitalize>
                    Following
                  </Text>
                </Link>
              </ListItem>

              <ListItem>
                <Link
                  icon
                  css={onlineFeedLinkCss}
                  href={AppRoutes.PURCHASES}
                  active={asPath === AppRoutes.PURCHASES}
                >
                  <Icon icon="Tags" css={{ ...LinkIconCss, padding: 7 }} />
                  <Text type="body2" capitalize>
                    Purchases
                  </Text>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  icon
                  css={onlineFeedLinkCss}
                  href={AppRoutes.ACTIVE_SUBS}
                  active={asPath === AppRoutes.ACTIVE_SUBS || asPath === AppRoutes.EXPIRED_SUBS}
                >
                  <Lock css={{ ...LinkIconCss, marginLeft: 8, minWidth: 30, minHeight: 30 }} />
                  <Text type="body2" capitalize>
                    Subscriptions
                  </Text>
                </Link>
              </ListItem>

              <ListItem>
                <Link
                  icon
                  css={onlineFeedLinkCss}
                  active={pathname === AppRoutes.LIKES_FEED.toLowerCase()}
                  href={AppRoutes.LIKES_FEED.toLowerCase()}
                >
                  <Icon icon="Heart" css={{ ...LinkIconCss, padding: 7 }} />
                  <Text type="body2" capitalize>
                    Likes
                  </Text>
                </Link>
              </ListItem>
            </>
          )}
        </MenuGroupList>
      </MenuSection>

      <MenuSection>
        <MenuGroupList>
          {store.user.isLoggedIn && (
            <>
              <ListItem>
                <GradientNavLink
                  icon
                  css={FeedLinkCss}
                  href={AppRoutes.REWARDS}
                  active={asPath === AppRoutes.SCHEDULED_POSTS}
                >
                  <Icon icon="AsteriskGradient" css={{ marginRight: 9 }} iconSize={22} />
                  <Text type="body2" capitalize css={{ marginLeft: '0px' }}>
                    Rewards
                  </Text>
                </GradientNavLink>
              </ListItem>
            </>
          )}
        </MenuGroupList>
      </MenuSection>
    </Nav>
  )
})
